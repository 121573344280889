<template>
  <div class="main">
    <div class="" v-html="content"></div>
    <div class="" v-html="content1"></div>
    <div class="" v-html="content2"></div>
    <div class="" v-html="content3"></div>
    <div class="" v-html="content4"></div>
    <div class="" v-html="content5"></div>
    <div class="" v-html="content6"></div>
    <div class="" v-html="content7"></div>
    <div class="" v-html="content8"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'AgreeMent',
  data() {
    return {
      content: `<p style="text-align:center">
    <strong><span style="font-size:29px">老岳讲感统服务协议</span></strong>
</p>
<p style="text-indent:28px">
    欢迎申请使用河南老岳讲感统教育科技有限公司及关联公司（以下简称“老岳讲感统平台”）提供的老岳讲感统软件（以下简称“本软件”）。
</p>
<p style="text-indent:28px">
   本协议是您与老岳讲感统平台手机客户端就老岳讲感统平台服务等相关事宜所订立的契约，具有合同效力。您（以下或简称“用户”）在使用老岳讲感统平台提供的各项服务（以下简称“本服务”）之前，请您务必审慎阅读、充分理解并选择接受或不接受本服务协议。
</p>`,
      content1: `<p style="text-indent:28px">
    一、 服务条款的确认和接纳
</p>
<p style="text-indent:28px">
    1．本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
</p>
<p style="text-indent:28px">
    2．用户同意本协议的，即视为用户确认自己具有享受本软件服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。
</p>
<p style="text-indent:28px">
    3．老岳讲感统平台有权根据需要不时地制定、修改本协议或各类规则，如本协议有任何变更，老岳讲感统平台将在网站上刊载公告，通知予用户。如用户不同意相关变更，必须停止使用“服务”。经修订的协议一经不然就公布后，立即自动生效。各类规则会在发布后生效，亦成为本协议的一部分。登录或继续使用“服务”将表示用户接受经修订的协议。
</p>
<p style="text-indent:28px">
    4．老岳讲感统平台保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
</p>`,
      content2: `<p style="text-indent:28px">
    二、 本软件的获取
</p>
<p style="text-indent:28px">
   您可以从老岳讲感统的第三方获取本软件，如果您从未授权的第三方获取本软件或与本软件名称相同的安装程序，则河南老岳讲感统教育科技有限公司无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
</p>
<p style="text-indent:28px">
    三、 本软件的安装与卸载
</p>
<p style="text-indent:28px">
   老岳讲感统平台为部分不同的终端设备开发了不同的软件版本，您应当根据实际情况选择合适下载合适的版本进行安装。下载安装程序后，您需要按照该程序提示的步骤正确安装。为提供更加优质、安全的服务。如果您不在需要使用本软件或者需要安装新版软件，可以自行卸载。
</p>
<p style="text-indent:28px">
    四、 本软件的更新
</p>
<p style="text-indent:28px">
  为了改善用户体验、完善服务内容，老岳讲感统平台不断努力开发新的服务，并为您不时提供软件更新，为了保证本软件及服务的安全性和功能的一致性，老岳讲感统平台有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。老岳讲感统平台不保证旧版本软件可用及相应的客户服务，请您随时核对并下载最新版本。
</p>`,
      content3: `<p style="text-indent:28px">
    五、 用户权利与义务
</p>
<p style="text-indent:28px">
    1.用户承诺：
</p>
<p style="text-indent:28px">
   1.1不得利用老岳讲感统平台制作、复制、查阅和传播下列信息：
</p>
<p style="text-indent:28px">
    -任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、淫秽的信息资料；
</p>
<p style="text-indent:28px">
    -任何教唆他人进行违法犯罪行为的资料；
</p>
<p style="text-indent:28px">
    -不利于国内团结和社会安定的资料；
</p>
<p style="text-indent:28px">
    -任何不符合国家法律、法规规定的资料、信息；
</p>
<p style="text-indent:28px">
    -任何捏造或者歪曲事实，散布谣言，扰乱社会秩序的信息；
</p>
<p style="text-indent:28px">
    -损害老岳讲感统平台及其关联主体的声誉和商业利益的信息；
</p>
<p style="text-indent:28px">
    1.2遵守中华人民共和国的法律、法规、规章、条例，以及其他具有法律效力的规范，不使用网络服务做非法用途；
</p>
<p style="text-indent:28px">
    1.3不干扰或混乱网络服务；
</p>
<p style="text-indent:28px">
   1.4不得侵犯老岳讲感统平台所有著作权、版权；
</p>
<p style="text-indent:28px">
   1.5不得在老岳讲感统平台内发布违法信息，用户对其发布的内容单独承担法律责任；
</p>
<p style="text-indent:28px">
    本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。
</p>
<p style="text-indent:28px">
    2．用户必须自行准备如下设备和承担如下开支：
</p>
<p style="text-indent:28px">
    2.1上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
</p>
<p style="text-indent:28px">
    2.2上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等；
</p>
<p style="text-indent:28px">
   3.用户须对自己在本站的言论和行为承担法律责任。若用户未遵守以上规定的，老岳讲感统平台有权作出独立判断并采取暂停或关闭用户账号等措施。
</p>
<p style="text-indent:28px">
   4. 用户参加老岳讲感统平台推广活动或购买课程时，存在恶意批量注册、非法交易、违反活动规则或刷单等作弊行为的，老岳讲感统平台有权作出独立判断并采取暂停或关闭用户账号或部分功能等措施。
</p>
<p style="text-indent:28px">
    4.1 作弊的行为包括但不限于下列行为：
</p>
<p style="text-indent:28px">
    - 利用系统漏洞获取优惠或物料等；
</p>
<p style="text-indent:28px">
   - 使用第三方渠道（即非老岳讲感统平台官方渠道）代充值；
</p>
<p style="text-indent:28px">
    - 违规贩卖账号、课程、优惠券、物料等，或将其做异常使用等。
</p>
<p style="text-indent:28px">
    特别注意：任何形式的刷单、刷礼品、刷评论等都属于作弊行为。
</p>
<p style="text-indent:28px">
    4.2 作弊的处罚指向所有通过非正当行为获得的积分、优惠、购买的课程等，作弊所得将被全部扣除或收回。涉嫌作弊行为一经初步确认，涉及该涉嫌作弊行为的所有用户账号将被酌情扣分、有限期封禁，情节严重者将被永久封禁账号，甚至被追究刑事责任。
</p>`,
      content4: `<p style="text-indent:28px">
    六、 用户个人信息保护
</p>
<p style="text-indent:28px">
   1. 用户应自行诚信向本软件提供注册资料，用户同意其提供的资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且老岳讲感统平台保留终止用户使用老岳讲感统平台各项服务的权利。
</p>
<p style="text-indent:28px">
  2. 用户在本软件进行浏览、购买课程、参与直播课程等活动时，会请求开启摄像头、录音等权限，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱、视频、音频等隐私信息的，老岳讲感统平台将予以严格保密，除非得到用户的授权或法律另有规定，老岳讲感统平台不会向外界披露用户隐私信息。
</p>
<p style="text-indent:28px">
   3. 用户注册成功后，将产生用户名和密码等账户信息，您可以根据本软件规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知老岳讲感统。
</p>
<p style="text-indent:28px">
   4. 用户务必确保用户名及密码的安全性。如果丢失，造成的损失将由本人承担全部后果。用户对利用该账号及密码所进行的一切活动负全部责任；因此所衍生的任何损失或损害，老岳讲感统平台不承担任何责任。
</p>
<p style="text-indent:28px">
   5. 用户不得将本软件注册获得的账号借予他人或多人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。如果发现或者有正当的理由怀疑多人共用一个账号的现象，老岳讲感统平台保留立即结束或终止该账号的权利。
</p>
<p style="text-indent:28px">
    6. 用户同意，老岳讲感统讲感统讲感统教育拥有通过邮件、短信电话等形式，向在本站注册、购物用户、收货人发送订单信息、学习信息、促销活动等告知信息的权利。
</p>
<p style="text-indent:28px">
   7. 结束用户服务后，用户使用老岳讲感统平台网络服务的权利随即终止。终止后用户没有权利，老岳讲感统平台也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
</p>`,
      content5: `<p style="text-indent:28px">
    七、社区评论公约
</p>
<p style="text-indent:28px">
   老岳讲感统平台致力于建立友善真诚平等的学习社区，鼓励同学们分享交流有价值有趣味的观点，鼓励用户基于尊重理解以学习为前提的原则进行互动。
</p>
<p style="text-indent:28px">
   老岳讲感统平台不允许违反法律法规及妨害社区秩序的行为，一经发现相关内容及账号将被关入小黑屋哟！
</p>
<p style="text-indent:28px">
    （一）违反法律法规
</p>
<p style="text-indent:28px">
   老岳讲感统平台严格遵守《互联网信息服务管理办法》及相关法律法规，禁止包含以下信息的违法违规内容出现，以下情形中，我们可能会收集使用你的相关个人信息无需征求您的授权同意：
</p>
<p style="text-indent:28px">
    1.反对宪法所确定的基本原则的；
</p>
<p style="text-indent:28px">
    2.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
</p>
<p style="text-indent:28px">
    3.损害国家荣誉和利益的；
</p>
<p style="text-indent:28px">
    4.煽动民族仇恨、民族歧视，破坏民族团结的；
</p>
<p style="text-indent:28px">
    5.破坏国家宗教政策，宣扬邪教和封建迷信的；
</p>
<p style="text-indent:28px">
    6.散布谣言，扰乱社会秩序，破坏社会稳定的；
</p>
<p style="text-indent:28px">
    7.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
</p>
<p style="text-indent:28px">
    8.侮辱或者诽谤他人，侵害他人合法权益的；
</p>
<p style="text-indent:28px">
    9.含有法律、行政法规禁止的其他内容的。
</p>`,
      content6: `<p style="text-indent:28px">
    （二）用户帐号违规
</p>
<p style="text-indent:28px">
  老岳讲感统平台严格遵守《互联网信息服务管理办法》及相关法律法规，保护用户的个人隐私。用户拥有设置个性化帐号信息的权利(包括：昵称、头像等)，但不得设置含有以下内容的帐号信息：
</p>
<p style="text-indent:28px">
    1.违反国家法律法规的；
</p>
<p style="text-indent:28px">
    2.包含人身攻击性质内容的；
</p>
<p style="text-indent:28px">
    3.暗示二维码或其他链接的；
</p>
<p style="text-indent:28px">
    4.包含其他非法信息的。
</p>
<p style="text-indent:28px">
    不得使用隐晦表达等方式规避以上限制。
</p>
<p style="text-indent:28px">
    （三）妨碍社区秩序
</p>
<p style="text-indent:28px">
  老岳讲感统平台重视良好的社区评论氛围，持续治理包含但不限于以下的妨害社区秩序行为：
</p>
<p style="text-indent:28px">
    1.不友善态度：
</p>
<p style="text-indent:28px">
    冷嘲热讽 – 评论或回复时应平和坦率，不应使用尖刻的语气讥笑讽刺；
</p>
<p style="text-indent:28px">
    恶俗玩笑 – 评论或回复时应保持尊重，不应视冒犯为幽默给其他用户造成困扰；
</p>
<p style="text-indent:28px">
    攻击引战 – 评论或回复时应就事论事，不应上升问题至偏激否定某个用户。
</p>
<p style="text-indent:28px">
    2.侵犯他人：
</p>
<p style="text-indent:28px">
    泄露隐私 – 未经同意人肉搜索其他用户，将其个人地址、电话、证件等信息对外披露；
</p>
<p style="text-indent:28px">
    诋毁诬陷 – 不得脱离事实传播有损其他用户、老师形象的内容，如给老师组CP；
</p>
<p style="text-indent:28px">
    冒充盗用 – 不可冒充及擅自代表其他用户、老师或机构，或谎称与其有关；
</p>
<p style="text-indent:28px">
    抄袭剽窃 – 转载不得侵犯他人权益且应注明来源，不应擅自将他人原创内容以自己名义发表或篡改。
</p>
<p style="text-indent:28px">
    骚扰威胁 – 不得用任何方式勉强逼迫他人实现自身要求，如骗赞；
</p>
<p style="text-indent:28px">
    诱骗欺诈 – 不得为实现目的捏造或隐瞒必要信息，骗取他人信任，或使他人在不知情前提下做出违背意愿的行为。
</p>
<p style="text-indent:28px">
    3.破坏社区环境：
</p>
<p style="text-indent:28px">
    垃圾广告 – 不应重复发表无意义内容及推广营销或向其他平台引流的言论；
</p>
<p style="text-indent:28px">
    脏话粗口 – 发表言论注意文明礼貌，不应使用易引起不适的粗俗字眼（还是那条，毕竟我们是友好交流社区）
</p>
<p style="text-indent:28px">
    自残自毁 – 不应发表自残自毁等引发社区恐慌的内容。
</p>
<p style="text-indent:28px">
    学习无关 – 学习话题外交友、拜师、同人文、玄幻魔道小说、耽美、明星、恋爱、组CP、小说、星座、动漫、骗赞、灌水等非学习类内容。
</p>`,
      content7: `<p style="text-indent:28px">
    八、 服务使用规范
</p>
<p style="text-indent:28px">
    1．退费说明
</p>
<p style="text-indent:28px">
    <span style="color:red">&nbsp;</span>
</p>
<p style="text-indent:28px">
    1.1退费到账细则：
</p>
<p style="text-indent:28px">
    1.1.1原路返还：纯在线支付（微信、支付宝、京东）的365天内的订单，退课后费用在5个工作日之内原路返还。
</p>
<p style="text-indent:28px">
    1.1.2线下退费：订单支付超过365天、重复支付退网校余额，费用只能登记线下银行卡退费（储蓄卡），15个工作日到账。
</p>
<p style="text-indent:28px">
    <span style="color:red">&nbsp;</span>
</p>
<p style="text-indent:28px">
    2．课程信息及变更
</p>
<p style="text-indent:28px">
    在您下订单时，请您仔细确认所购课程的名称、价格、联系地址、电话、收货人等信息。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。
</p>
<p style="text-indent:28px">
    本站上的课程内容、价格等信息随时都有可能发生变动，本站不作特别通知。网站上课程数量庞大，由于众所周知的互联网技术因素等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解。
</p>
<p style="text-indent:28px">
    九、免责声明
</p>
<p style="text-indent:28px">
   1．老岳讲感统平台不保证（包括但不限于）：
</p>
<p style="text-indent:28px">
    1.1本服务不受干扰，及时、安全、可靠或不出现错误；
</p>
<p style="text-indent:28px">
    1.2用户经由本服务取得的任何产品、服务或其他材料符合用户的期望；
</p>
<p style="text-indent:28px">
    2．用户使用经由本服务下载的或取得的任何资料，其风险自行负担；
</p>
<p style="text-indent:28px">
   3．由于地震、台风、洪水、火灾、战争、政府禁令以及其他不能预见并且对其发生和后果不能防止或避免的不可抗力或互联网上的黑客攻击事件，致使影响本服务条款的履行，老岳讲感统平台不承担责任。
</p>
<p style="text-indent:28px">
   4．用户同意老岳讲感统平台因教学需要而变更上课时间及授课老师。
</p>
<p style="text-indent:28px">
    十、 知识产权
</p>
<p style="text-indent:28px">
    本网站提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，老岳讲感统讲感统讲感统教育或其关联公司享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。未经权利人书面同意，用户均不得将上述资料在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行,或者被用于其他任何商业目的。所有以上资料或资料的任何部分仅可作为私人和非商业用途保存。
</p>
<p style="text-indent:28px">
    十一、 争议解决
</p>
<p style="text-indent:28px">
    1．本政策之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律。如法律无相关规定的，参照商业惯例及/或行业惯例。
</p>
<p style="text-indent:28px">
   2．您因使用老岳讲感统平台服务所产生及与老岳讲感统平台服务有关的争议，由老岳讲感统平台与您协商解决。协商不成时，任何一方均可向北京市海淀区人民法院提起诉讼。
</p>
<p style="text-indent:28px">
    十二、 其他
</p>
<p style="text-indent:28px">
   1．用户知悉并同意，老岳讲感统平台将向用户拨打电话或发送短信以提供更好的服务。
</p>
<p style="text-indent:28px">
   2．老岳讲感统平台有权在必要时修改本服务条款以及各单项服务的相关条款。用户在享受单项服务时，应当及时查阅了解修改的内容，并自觉遵守本服务条款以及该单项服务的相关条款。
</p>
<p style="text-indent:28px">
   3．本协议内容同时包括《老岳讲感统平台用户个人信息保护政策》及您在使用老岳讲感统平台某项特定服务时可能另有的单独协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应遵守。本协议与《老岳讲感统平台用户个人信息保护政策》不一致的，以《老岳讲感统平台用户个人信息保护政策》约定为准。您对前述任何单独协议的接受，即视为您对本协议全部的接受。
</p>`,
      content8: `<p style="text-indent:28px">
    十三、 联系方式
</p>
<p style="text-indent:28px">
   当您有个人信息相关问题或其他的投诉、建议等，可以通过如下方式与老岳讲感统平台联系，老岳讲感统平台将尽快审核所涉及内容，并于15个工作日内对于您的问题、投诉、建议进行回复：
</p>
<p style="margin-left:28px">
   平台运营主体名称：河南老岳讲感统教育科技有限公司
</p>
<p style="margin-left:28px">
    &nbsp;
</p>
<p style="margin-left:28px">
    注册地址：河南省郑州市市辖区郑东新区万通街康平路郑东商业中心C区1号楼1层112号
</p>
<p style="margin-left:28px">
    &nbsp;
</p>
<p style="margin-left:28px">
    联系地址：河南省郑州市管城回族区康平路郑东商业中心C区2号楼1610
</p>
<p style="margin-left:28px">
    &nbsp;
</p>
<p style="margin-left:28px">
    联系电话：400-088-0016
</p>
<p style="text-indent:28px">
    <span style="color:red">&nbsp;</span>
</p>
<p>
    <br/>
</p>`,
    };
  },
  onShow() {},
  methods: {},
};
</script>


<style>
.main {
  font-size: 28rpx;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  padding: 24rpx;
}
</style>
