
import {createRouter, createWebHistory} from 'vue-router'
import agreement from '../../src/view/agreement';
import policy from '../../src/view/policy';
 
const router = createRouter({
  history: createWebHistory(),     //路由模式
  routes: [
    {
      path: '/',
      name: 'Policy',
      component: policy,
      meta: { title: '隐私政策' }
    },
    {
      path: '/agreement',
      name: 'Agreement',
      component: agreement,
      meta: { title: '服务协议' }
    }
  ]
})

export default router