import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

router.afterEach((to) => {
  if (to.meta.title || to.query.title) {
    const title = to.query.title || to.meta.title
    document.title = title
  }
})

createApp(App).use(router).mount('#app')