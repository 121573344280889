
<template>
  <div class="main">
    <div class="" v-html="content1"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'PolicyText',
  data() {
    return {
      title: "隐私政策",
      content1: `<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">老岳讲感统隐私政策</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">更新日期：</span>202</span><span style="font-family: 微软雅黑;font-size: 16px">2</span><span style="font-family: 微软雅黑;font-size: 16px">年</span><span style="font-family: 微软雅黑;font-size: 16px">12</span><span style="font-family: 微软雅黑;font-size: 16px">月</span><span style="font-family: 微软雅黑;font-size: 16px">2</span><span style="font-family: 微软雅黑;font-size: 16px">日</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">生效日期：</span>202</span><span style="font-family: 微软雅黑;font-size: 16px">2</span><span style="font-family: 微软雅黑;font-size: 16px">年</span><span style="font-family: 微软雅黑;font-size: 16px">4</span><span style="font-family: 微软雅黑;font-size: 16px">月</span><span style="font-family: 微软雅黑;font-size: 16px">9</span><span style="font-family: 微软雅黑;font-size: 16px">日</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑;color: rgb(51, 51, 51)">欢迎访问老岳讲感统！河南老岳讲感统教育科技有限公司（下称</span></strong><strong><span style="font-family: 微软雅黑">“我们”</span></strong><strong><span style="font-family: 微软雅黑;color: rgb(51, 51, 51)">）负责老岳讲感统网站及老岳讲感统手机应用软件的开发、运营、管理。</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">我们深知个人信息对用户的重要性，老岳讲感统将按照相关法律法规的要求，采取相应安全保护措施，尽力保护用户</span>(以下简称 “您”)的个人信息安全可控。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">《老岳讲感统隐私政策》，本隐私政策我们承诺将按照本隐私政策收集、使用</span> <span style="font-family:微软雅黑">和披露用户信息，本隐私政策适用于老岳讲感统的所有产品及服务。</span></span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">在使用老岳讲感统各项产品或服务前，请仔细阅读本政策，</span> <span style="font-family:微软雅黑">在确认充分理解并</span> <span style="font-family:微软雅黑">同意后使用相关产品或服务。一旦您开始使用老岳讲感统的产品或服务，即表示您已充分理解并同意本政策。</span></span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">我们的产品基于DCloud uni-app(5+ App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI 信息、OAID）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。详情内容请访问《DCloud用户服务条款》。（DCloud用户服务条款超链至：<a style="color: #FF6B18" href="https://ask.dcloud.net.cn/protocol.html">https://ask.dcloud.net.cn/protocol.html</a>）</span></span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">移动智能终端补充设备标识体系与 SDK 严格遵守我国《网络安全法》、《数据安全法》和《电信和互联网用户个人信息保护规定》等相关法律法规和《信息安全技术 个人信息安全规范》等国家标准要求。SDK 不会主动收集任何数据，只在 APP 调用时获取以下数据，用于本地判断，不会进行任何网络传输：</span></span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
  <ul><li><strong><span style="font-family:微软雅黑">设备制造商、设备型号、设备品牌，用于判断终端调用接口</span></strong></li><li><strong><span style="font-family:微软雅黑">设备网络运营商名称，用于判断虚拟机环境</span></strong></li><li><strong><span style="font-family:微软雅黑">APP 包名，用于校验签名</span></strong></li></ul>
</p>

<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">一、个人信息定义及范围</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">1、</span><strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">个人信息</span>:</span></strong><span style=";font-family:宋体;font-size:16px">&nbsp;</span><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括</span>(但不限于)，您的姓名、地址(包括但不限于注册城市、收货地址)、电子邮件地址、生日、电话号码以及、性别、职业、种族、年龄、兴趣爱好、您的个人看法、观点或偏好、您发送的私人或保密性质的函件以及他人对您的观点或看法。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">2、个人敏感信息:指一旦泄露、非法提供或滥用可能危人身和财产安全，极易 导致个人名誉、身心健康受到损害或歧视性待遇的个人信息。包括</span><strong><span style="font-family: 微软雅黑">身份证件号码</span></strong><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">、手机号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易</span> <span style="font-family:微软雅黑">信息、</span>14 岁以下(含)儿童的个人信息。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">二、我们如何收集和使用您的个人信息（我们将在app启动时提示该隐私政策，您可以阅读该协议详细了解）</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">为了向您提供服务，我们将根据您使用的服务收集您的如下信息</span>:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">1、成为老岳讲感统用户</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">在使用老岳讲感统的部分服务前，您需要先成为老岳讲感统的注册用户，以便我们提供服务。注册时需要提供账号使用的</span><strong><span style="font-family: 微软雅黑">手机号码</span></strong><span style="font-family: 微软雅黑;font-size: 16px">，并且您可以自愿选择填写您的</span><strong><span style="font-family: 微软雅黑">昵称、性别、所在地、生日、职业，您孩子的姓名、性别、生日、身高、体重、视力、家庭人数、家庭情况</span></strong><span style="font-family: 微软雅黑;font-size: 16px">基本信息并设置密码。</span><strong><span style="font-family: 微软雅黑">如果您不提供这些信息，并不会影响您使用老岳讲感统的基本功能。如果您仅需使用浏览、搜索及查看基本服务及介绍，您不需要注册及提供上述信息</span></strong><span style="font-family: 微软雅黑;font-size: 16px">。成为老岳讲感统用户后，您可以对个人信息进行修改。在您注销老岳讲感统账户后，我们将停止为您提供产品或服务，并根据法律规定删除您的个人信息，或进行匿名处理。使用头像功能时，我们会向您告知我们将收集头像信息的相机权限、存储权限，并获得您的同意。我们向您获取相机权限以帮助您提供您的用户头像照片；我们向您获取存储权限以完成您调整您的用户头像照片。若您认为该信息属于与业务功能有关的非必要或无关的信息，您可以选择不同意我们收取和使用您的该部分信息，如果您选择不同意，则可以选择停止使用老岳讲感统的产品或服务。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">2、为向您提供课程服务，您向平台提供信息，并将这些信息进行关联， 这些功能和信息包括:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(1) 购买:您需要向平台提供联系人</span><strong><span style="font-family: 微软雅黑">姓名、手机号码、地址信息</span></strong><span style="font-family: 微软雅黑;font-size: 16px">&nbsp;(如涉及到需要邮寄教具、书籍或其他商品的服务时)，以供平台为您在具体产品提供课程及相关的等产品购买服务。在您购买课程产品后，我们会</span><strong><span style="font-family: 微软雅黑">提供作业上传服务，您可以选择上传图片、视频文件，请您知悉并充分理解此操作需要获取访问您的设备照片、媒体内容和文件权限</span></strong><span style="font-family: 微软雅黑;font-size: 16px">;</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(2)支付:您在老岳讲感统平台上支付时，可以选择老岳讲感统合作的第三方支付 机构(微信支付、支付宝)所提供的第三方服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(3)上课:您可观看已经购买的课程直播和在有效期内的回放视频。在直播的过程中可以参与老师的教学互动，例如讨论区评论、举手发言、连麦、抢红包 等行为。</span><strong><span style="font-family: 微软雅黑">参与互动需要获取您的麦克风、摄像头权限</span></strong><span style="font-family: 微软雅黑;font-size: 16px">。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(4)预约功能:您可以预约购买老岳讲感统及其联盟机构提供的线下测评服务，需要您选择门店，预约日期，为了方便给您精确推荐，我们需要访问您的位置信息。这个过程将会获取您的</span><strong><span style="font-family: 微软雅黑">定位权限</span></strong><span style="font-family: 微软雅黑;font-size: 16px">。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(5)信息发布:您在老岳讲感统平台上主动对产品/服务进行评价或发布其他内容(如发表评论信息，发布动态信息)时，平台将收集您发布的信息，并展示您的昵称、头像、发布内容、发布日期。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(</span><span style="font-family: 微软雅黑;font-size: 16px">6</span><span style="font-family: 微软雅黑;font-size: 16px">)客户服务:当您与平台的客服取得联系时，平台的系统可能会记录您与客服之间的通讯记录，以及使用您的账号信息以便核验身份；当您需要平台提供与您订单相关的客户服务时，平台可能会查询您的相关订单信息以便给予您适当的帮助和处理；当您需要客服协助您修改有关信息(如配送地址、联系方式)时，您可能还需要提供上述信息外的其他信息以便完成修改。用户在使用客服功能时，我们会告知您并向您取得收集上述客服服务相关的通信记录等信息的相机权限、存储权限等，并获得您的同意。我们向您获取相机权限以帮助您更清晰地描述您在使用我们产品过程中遇到的问题；我们向您获取存储权限以更高地效率解决您在使用我们产品过程中遇到的问题。若您认为该信息属于与业务功能有关的非必要或无关的信息，您可以选择不同意我们收取和使用您的该部分信息，如果您选择不同意，则可以选择停止使用老岳讲感统的产品或服务。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(</span><span style="font-family: 微软雅黑;font-size: 16px">7</span><span style="font-family: 微软雅黑;font-size: 16px">)账单管理:为展示您账号的订单信息，平台会收集您在使用平台服务过程 中产生的订单信息用于向您展示及便于您对订单进行管理。您在老岳讲感统生成的订单中，将可能包含您的身份信息、联系信息、地址信息、支付信息，这些都属于敏感信息，请您谨慎向他人展示或对外提供，如老岳讲感统需对外提供订单信息时，将取得您的授权，并尽到合理商业注意义务对您的信息进行去标识 化处理以在最大化保护您的个人信息同时实现信息可用性。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(</span><span style="font-family: 微软雅黑;font-size: 16px">8</span><span style="font-family: 微软雅黑;font-size: 16px">)积分商城:您可以使用积分在积分商城兑换虚拟或实体礼品，若为实体礼品则需要您提供</span><strong><span style="font-family: 微软雅黑">收件人姓名、手机号、收货地址</span></strong><span style="font-family: 微软雅黑;font-size: 16px">，若您不填写信息我们将无法为您提供兑换的奖励。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(</span><span style="font-family: 微软雅黑;font-size: 16px">9</span><span style="font-family: 微软雅黑;font-size: 16px">)通话权限：您首次下载安装老岳讲感统APP后，我们会向您发送征求您是否同意我们使用您的老岳讲感统APP的通话权限的弹窗，我们获取您的老岳讲感统APP通话权限以便于您在需要联系我们使唤醒拨号键盘并键入电话号码，我们并不会直接呼叫，需要您再次点击，您可以在弹窗中选择允许我们获取您的电话权限，也可以选择不允许我们获取您的电话权限。若您选择允许我们获取您的电话权限，则对于您同意使用的电话权限，我们将仅在向您提供老岳讲感统点击拨打电话按钮时使用，若您选择不允许我们获取您的电话权限，也不会影响您继续使用老岳讲感统APP的功能。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">当我们收集并使用这些信息实现上述功能时，我们通过动态弹窗征求您的同意，您可以选择同意或拒绝，当您选择同意即代表您授权我们收集并使用您的信息，当您选择拒绝即代表您未授权我们收集并使用您的信息，我们将不会收集和使用您的信息，也可能无法为您提供上述服务。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">3、我们通过不断提升的技术手段加强安装在您的设备的软件的安全能力，以防您的个人信息泄露，为了保障向您提供的服务的安全稳定运营，预防病毒、木马程序和其他恶意程序、网站，我们会收集关于您使用产品、服务以及使用方式的信息并将这些信息进行关联，这些信息包括:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">(1)为识别您的设备ID并预防恶意程序及反作弊、提高服务安全性、判断设备是否激活、运行环境是否安全、保障交易安全,我们会收集您的设备信息(包括IMEI、MEID、OAID、Android ID、
IMSI、GUID)、您安装的应用信息或运行中的进程信息。使用场景：App启动时、注册、登录、下单。收集情况：新用户首次打开，触发交易等安全环境校验时收集1次</span></strong>
</p>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">(2)日志信息:当您使用我们的服务时，为对错误异常进行监控，以及对平台进行优化升级，我们可能会自动收集应用软件运行异常信息及服务的详细使用情况，作为有关网络日志保存，用于分析解决崩溃问题。日志信息 包括您的登录账号、搜索查询内容、IP 地址、浏览器的类型、电信运营商、网 络环境、使用的语言、访问日期和时间、崩溃记录、停留时长、发布记录。</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">(3)为实现运行故障报错及分析和解决您在使用老岳讲感统过程中所遇到的问题，判断是否为真实自然人用户，保障交易安全，我们将收集您的设备信息(GUID、MAC地址)、浏览和操作记录。使用场景：App启动、注册、登录、下单等场景。收集情况：触发安全交易的行为（如：下单、提现）时收集1次
</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">(4)为了在过程中防止欺诈及保护您的交易安全，我们将收集您的设备位置信息。
</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权</span> <span style="font-family:微软雅黑">限，征求您的同意，我们收集这些信息是为了向您提供我们基本服务和基础功</span> <span style="font-family:微软雅黑">能，如您拒绝提供上述权限将可能导致您无法使用我们的产品与服务。</span></span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">4、例外的情况：</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">4.1以下情形我们可不响应您提出的查询、更正、删除、撤回授权、注销、获取个人信息副本的请求，包括:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(1)与老岳讲感统履行法律法规规定的义务相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(2)与国家安全、国防安全直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(3)与公共安全、公共卫生、重大公共利益直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(4)与刑事侦查、起诉、审判和执行判决等直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(5)老岳讲感统有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(6)出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(7)响应用户的请求将导致用户或其他个人、组织的合法权益受到严重损害的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(8)涉及商业机密的。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">4.2 根据相关法律法规规定及国家标准，以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意：</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(1)与老岳讲感统履行法律法规规定的义务相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(2)与国家安全、国防安全直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(3)与公共安全、公共卫生、重大公共利益直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(4)与刑事侦查、起诉、审判和执行判决等直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(5)出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(7)用户自行向社会公众公开的个人信息；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(8)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">4.3 根据相关法律法规规定及国家标准，以下情形中，我们可能会依法收集、使用您的个人信息无需征得您的同意：</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(1)与个人信息控制者履行法律法规规定的义务相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(2)与国家安全、国防安全直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(3)与公共安全、公共卫生、重大公共利益直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(4)与刑事侦查、起诉、审判和判决执行等直接相关的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(5)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(6)所涉及的个人信息是个人信息主体自行向社会公众公开的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(7)根据个人信息主体要求签订和履行合同所必需的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(8)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(9)维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(10)个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(11)个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">三、我们如何使用</span> Cookie 和同类技术</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">“Cookie”是用作您计算机标识卡的计算机文件。我们或我们的第三方合作伙 伴，可能通过 cookies 收集和使用您的信息(包括:设备唯一标示、APP 版 本、登录鉴权 ID、三方统计 ID)，并将该信息储存为日志信息。我们使用 cookies 目的是为识别您的身份，帮您省去重复输入注册信息的步骤，或者帮 助判断您的帐号安全状态。我们可能会通过 cookies 收集非个人身份信息。如 果您的浏览器或浏览器附加服务允许，您可以修改对 Cookie 的接受程度或者拒 绝老岳讲感统的 Cookie，请您理解，我们的某些服务只能通过使用 Cookie 才可得到实现。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">四、我们如何共享您的个人信息</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">您同意我们依据本政策与下列信息接收方共享您的个人信息</span>(合称为“信息接收方”)：我们的关联方或相关法人团体；与我们合作的一些商业合作伙伴。我们可能委托商业合作伙伴为您提供某些服务或者代表我们履行职能(简称“我们信任的商业合作伙伴”)。我们仅会出于合法、正当、必要、特定、明确的目的在有限的程度内与其共享您的个人信息，即我们信任的商业合作伙伴为了提供其受聘所需服务而合理所必需的个人信息。我们信任的商业合作伙伴无权将共享的个人信息用于任何其他用途。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">目前我们信任的商业合作伙伴包括：服务提供商、供应商和其他合作伙伴。例如帮助我们运营网站，代表我们处理与您的个人信息相关的某些活动并向我们的客户和访客提供重要服务的服务提供商等。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">除以上信息接收方外，在获取您明确同意情况下，我们可能与其他信息接收方共享您的个人信息。对我们与之共享个人信息的信息接收方，我们会与其签署保密条款、要求他们按照我们的说明、本政策及其他任何相关的保密和安全措施来处理您的个人信息。信息接收方对您个人信息仅有有限的访问权，他们还承担契约义务，包括</span>(但不限于)使用符合相关法律规定的方式使用通过我们 获得的个人信息或以其他方式代表我们获取的任何个人信息，采取合理的安全 措施(包括适用法律所要求的措施)，并根据适用法律的规定和契约的约定承 担相关的法律责任。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">五、我们如何保护您的个人信息</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">老岳讲感统非常重视信息安全，努力使用各种安全技术和程序以防信息的丢失、</span> <span style="font-family:微软雅黑">不当使用、未经授权阅览或披露。例如，在某些服务中，将利用加密技术来保</span> <span style="font-family:微软雅黑">护用户向老岳讲感统提供的个人信息。</span></span><strong><span style="font-family: 微软雅黑">请您理解，由于技术的限制及风险防范的局限，即使我们尽力加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，用户接入互联网服务所使用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 宋体;font-size: 16px">Getui</span></strong><strong><span style="font-family: 宋体;font-size: 16px">&nbsp;</span></strong><strong><span style="font-family: 微软雅黑">SDK</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">使用目的：</span></strong><strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">提供手机号一键登录，检查</span>Mac</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">数据类型：设备识别信息</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">SDK服务提供商: 每日互动股份有限公司（简称“个推”）
</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">第三方隐私权政策链接：</span></strong><a href="https://www.getui.com/privacy"><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;color: rgb(0, 0, 0)">https://www.getui.com/privacy</span></span></strong></a>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">&nbsp;</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">1、用户的账户均有安全保护功能，用户应采取积极措施保护个人信息的安全， 包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息 透漏给他人等。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">2、除非经过用户同意，我们不允许任何用户、第三方通过我们收集、出售或者传播用户信息。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">3、老岳讲感统含有到其他网站的链接，我们不对那些网站的隐私保护措施负责。当用户登陆那些网站时，请提高警惕，保护个人隐私。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">4、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告 知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、 您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、短信、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按 照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">5、当因第三方SDK收集或发生收购等情况，而导致老岳讲感统与第三方成为共同信息控制者时，老岳讲感统会通过合同等形式与第三方共同确定个人信息安全要求，并且在个人信息安全方面承担相应的责任和义务，并向您明确告知。</span><span style="font-family: 微软雅黑;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">六、如何管理您的个人信息</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">我们鼓励您更新和修改您的个人信息以使其更准确有效，也</span><strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">请您理解，您更</span> <span style="font-family:微软雅黑">正、删除、撤回授权或停止使用老岳讲感统服务的决定，并不影响我们此前基于</span> <span style="font-family:微软雅黑">您的授权而开展的个人信息处理</span></span></strong><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">。除法律法规另有规定，当您更正、删除您的</span> <span style="font-family:微软雅黑">个人信息或申请注销账号时，我们可能不会立即从备份系统中更正或删除相应</span> <span style="font-family:微软雅黑">的个人信息，但会在备份更新时更正或删除这些个人信息。您可以通过以下方</span> <span style="font-family:微软雅黑">式来管理您的个人信息</span>:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">1、访问、查询、更正您的个人信息:如果您希望查询访问或更改您的账户中的个人信息 (包括昵称、头像、性别、年级、地区、电话、收货地址)，您可以登录“老岳讲感统App-我的-在线消息-人工客服”或电话服务渠道（电话：400-0</span><span style="font-family: 微软雅黑;font-size: 16px">88</span><span style="font-family: 微软雅黑;font-size: 16px">-0</span><span style="font-family: 微软雅黑;font-size: 16px">016</span><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">）联系我们，并提供您的注册手机号，在您核验通过后，将在</span>5个工作日内根据您的查询访问或更改请求执行您相应的查询访问或更正请求并完成操作。如果您希望查询您的ip、设备的设备标识信息、个人基本资料、个人身份信息、个人位置信息，您可以通过在线客服进行查询，在线客服将在5个工作日内向您反馈您想要查询的内容（包括但不限于收集的信息类型、用途、服务方的查询方式）。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">2、删除您的个人信息:您可以通过电话客服渠道（电话：400-0</span><span style="font-family: 微软雅黑;font-size: 16px">88</span><span style="font-family: 微软雅黑;font-size: 16px">-0</span><span style="font-family: 微软雅黑;font-size: 16px">016</span><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">）联系我们，并提供注册手机号，在您核验通过后，将在</span>5个工作日内帮您删除手机号、姓名、性别、昵称、收货地址、孩子信息、浏览记录、购课记录、设备标识的信息，并与您反馈。如我们停止运营或停止提供相关服务，我们将会及时停止收集您个人信息，并将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除 或匿名化处理。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">在以下情形中，您可以向我们提出删除个人信息的请求</span>:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(1)如果我们处理个人信息的行为违反法律法规;</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(2)如果我们收集、使用您的个人信息，却未征得您的授权同意;</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(3)如果我们处理个人信息的行为严重违反了与您的约定;</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(4)如果您不再使用我们的产品或服务，或您注销了账号;</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">(5)如果我们不再为您提供老岳讲感统的产品或服务。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个</span> <span style="font-family:微软雅黑">人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您</span> <span style="font-family:微软雅黑">的独立授权。</span></span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">3、撤回授权您的个人信息:部分个人信息是使用我们服务所必需的，但大多数 其他个人信息的提供是由您决定的。您可以通过解除绑定、修改个人设置、删 除相关信息等方式撤回部分授权，也可以通过关闭功能的方式撤销授权。您可以电话联系客服（电话服务渠道：400-088-0016）向我们提出撤回对于隐私政策协议内容的同意授权。</span><strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不</span> <span style="font-family:微软雅黑">再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理，我们会在</span>30天内响应、做出答复或合理解释</span></strong><span style="font-family: 微软雅黑;font-size: 16px">。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">4</span><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">、获取个人信息副本：您可以通过登录</span>“老岳讲感统App-我的-在线消息-人工客服”或电话服务渠道（电话：400-088-0016）联系我们并且告知我们您想要获取您的个人信息副本，并提供您的注册手机号，在您核验通过后，将在5个工作日内向您提供您的个人信息副本。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">5</span><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">、您的个人信息的存储期限：您提供的所有个人信息的存储期限为收集日期开始的</span>5年内，超出上述期限后，我们会对您的个人信息进行删除或匿名化处理。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">七、我们使用的第三方</span> SDK 服务</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">为实现本隐私政策中声明的功能，我们可能会接入第三方服务商提供的</span> SDK 或其他类似的应用程序，并将我们依照本隐私政策收集的个人信息共享给第三方服务商，以完善课程服务和用户体验</span></strong><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">。目前，我们接入的第三方服务商主要包括</span>:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style=";font-family:宋体;font-size:16px">Getui</span><span style=";font-family:宋体;font-size:16px">&nbsp;</span><strong><span style="font-family: 微软雅黑">SDK</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">使用目的：</span></strong><strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">提供手机号一键登录，检查</span>Mac</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">数据类型：设备识别信息</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">第三方隐私权政策链接：</span></strong><a href="https://www.getui.com/privacy"><strong><span style="text-decoration:underline;"><span style="font-family: 微软雅黑;color: rgb(0, 0, 0)">https://www.getui.com/privacy</span></span></strong></a>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">&nbsp;</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">如上所述服务由相关的第三方负责运营。您使用这些第三方服务</span>(包括您向这些第三方提供的任何个人信息)，须受第三方自己的服务条款及个人信息保护声明(而非本隐私政策)的约束，您需要仔细阅读其条款。我们仅会出于正当、必要、特定的目的共享您的信息。我们会要求他们履行相关保密义务并采 取相应的安全措施。</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">八、我们如何处理未成年人的个人信息</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">我们非常重视未成年人的信息保护，</span><strong><span style="font-family: 微软雅黑"><span style="font-family:微软雅黑">如您为未成年人，建议您请您的父母或监</span> <span style="font-family:微软雅黑">护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我</span> <span style="font-family:微软雅黑">们的服务或向我们提供信息</span></span></strong><span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">。对于经父母或监护人同意使用我们的产品或服务</span> <span style="font-family:微软雅黑">而收集未成年人个人信息的情况，我们只会在法律法规允许，父母或监护人明</span> <span style="font-family:微软雅黑">确同意或者保护未成年人所必要的情况下收集、使用、共享、转让或公开披露此信息。我</span> <span style="font-family:微软雅黑">们将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，根据</span> <span style="font-family:微软雅黑">国家相关法律法规及本政策的规定保护未成年人的个人信息。</span></span><strong><span style="font-family: 微软雅黑">如您的监护人不同意本隐私政策，您应立即停止使用老岳讲感统的服务并拒绝提供个人信息，需要进行修改或删除处理的，请随时与我们联系</span></strong><span style="font-family: 微软雅黑;font-size: 16px">。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">九、您的个人信息的跨境转移</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内，这意味着，原则上您的个人信息不会被转移到您使用产品或服务所在国家</span>/地区的境外管辖区。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">十、隐私政策的修订</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">我们可能适时修订本隐私政策的条款，本隐私政策为《老岳讲感统用户协议》的</span> <span style="font-family:微软雅黑">重要组成部分。对于重大变更，我们会提供更显著的通知，您如果不同意该等变更，可以选择停止使用老岳讲感统的产品或服务</span>;如您仍然继续使用老岳讲感统&nbsp;的产品或服务，即表示同意受经修订的本政策的约束。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">十一、法律适用与管辖</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应按照中华人民共和国法律予以处理，并由被告住所地人民法院管辖。</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <strong><span style="font-family: 微软雅黑">十二、联系我们</span></strong>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">如您对本隐私政策有任何问题、投诉、建议，通过以下方式与我们联系，我们将在</span> 15 个工作日内回复:</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">河南老岳讲感统教育科技有限公司</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px">联系地址：河南省郑州市管城回族区康平路郑东商业中心</span><span style="font-family: 微软雅黑;font-size: 16px">C区2号楼1610</span>
</p>
<p style="margin-top:12px;margin-bottom:12px;line-height:22px;background:rgb(255,255,255)">
    <span style="font-family: 微软雅黑;font-size: 16px"><span style="font-family:微软雅黑">电话</span>:400-088-0016</span>
</p>
<p>
    <span style=";font-family:等线;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  font-size: 28rpx;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  padding: 24rpx;
}
</style>
